(function () {
'use strict';

angular.module('aerosApp').
factory('OTDRLibResource', OTDRLibResource);

OTDRLibResource.$inject = ['ToolLibResource', 'ToolsDefinitionService', 'ToolModelPrototype'];
function OTDRLibResource (ToolLibResource, ToolsDefinitionService, ToolModelPrototype) {
  var otdrResource = ToolLibResource({toolType: 'otdr'}, {
    query  : { interceptor: {response: intercept}, isArray: false },
    update : { transformRequest: transformChangeRequest },
    create : { transformRequest: transformChangeRequest }
  });

  var modelPrototype = ToolModelPrototype(otdrResource);
  var format = ToolsDefinitionService.format;

  return otdrResource;

  function transformChangeRequest (data) {
    return angular.toJson({
      tool: format.flat2nested(data)
    });
  }

  // using interceptor for transformation b/c it can be async unlike transformResponse
  function intercept (res) {
    return ToolsDefinitionService.fetch().
      then(_.partial(process, 'OTDR', res.data.tools));
  }

  function process (toolType, tools, defs) {
    var format = ToolsDefinitionService.format;
    var groups = _.map(defs[toolType].groups, 'id');
    return _.chain(tools).
      map(format.nested2flatExcept([])).
      map(format.modelizeItem(modelPrototype, {toolType: toolType})).
      value();
  }
}

}());